<div class="headerC">
  <h1>Firma individual</h1>
</div>
<div class="container">
  <div class="container">
    <form [formGroup]="formOptions">
      <div class="col-8" style="margin-bottom: 10px">
        <mat-label>Seleccione la cantidad de hojas que contiene el archivo</mat-label>
        <mat-radio-group formControlName="pages" (change)="handleChangeSign($event)">
          <mat-radio-button color="primary" style="margin-left: 5px; margin-right: 5px" *ngFor="let page of pages" [value]="page">
            {{page.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field class="col-6" color="primary" appearance="outline">
        <mat-label>Seleccione la hoja a firmar</mat-label>
        <mat-select formControlName="sign">
          <mat-option *ngFor="let sign of signsFiltered" [value]="sign">
            {{sign.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="formOptions.controls['sign'].value.id === 4 && formOptions.controls['pages'].value.id !== 1"
        class="col-6" color="primary" appearance="outline">
        <mat-label>Ingrese el número de página</mat-label>
        <input matInput placeholder="Ej. 4" type="number" formControlName="number">
      </mat-form-field>
    </form>
  </div>

  <div class="container" style="margin-top: 25px;">
    <form [formGroup]="form">
      <div class="form-row animated fadeIn slow">
      </div>
      <div class="card text-center animated fadeIn slow">
        <div class="card-header">
          ADJUNTAR DOCUMENTO
        </div>
        <div class="card-body">
          <div class="row col-md-12">
            <div class="form-group col-lg-4">
              <label>Certificado (.cer)</label>
              <input formControlName="archivoCer" type="file" #fileCer hidden (change)="onFileSelected($event, 'cer')"/>
              <div class="input-group col-xs-12">
                <input type="text" [value]="cerFile != null ?  cerFile.name : ''" class="form-control file-upload-info"
                       disabled="" placeholder="">
                <span class="input-group-append">
          <button class="file-upload-browse btn btn-dark" (click)="fileCer.click()" type="button">Cargar .cer</button>
        </span>
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label>Llave privada (.key)</label>
              <input formControlName="archivoKey" type="file" #fileKey hidden (change)="onFileSelected($event, 'key')"/>
              <div class="input-group col-xs-12">
                <input type="text" [value]="keyFile != null ? keyFile.name : ''" class="form-control file-upload-info"
                       disabled="" placeholder="">
                <span class="input-group-append">
          <button class="file-upload-browse btn btn-dark" (click)="fileKey.click()" type="button">Cargar .key</button>
        </span>
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label for="contrasenaKey">Contraseña llave privada</label>
              <input formControlName="contrasenaKey" ngModel type="password" class="form-control" id="contrasenaKey" required>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label>Documento (.pdf)</label>
            <input formControlName="archivoPdf" type="file" hidden (change)="onFileSelected($event, 'pdf')" #filePdf/>
            <div class="input-group col-xs-12">
              <input type="text" [value]="pdfFile != null ? pdfFile.name : ''" class="form-control file-upload-info"
                     disabled="" placeholder="">
              <span class="input-group-append">
            <button class="file-upload-browse btn btn-dark" (click)="filePdf.click()" type="button">Cargar .pdf</button>
          </span>
            </div>
          </div>
          <div class="buttons-row">
            <button mat-raised-button (click)="Firmar()" color="primary">Firmar</button>
            <button mat-raised-button (click)="handleCleanForm()" color="accent">Limpiar</button>
          </div>
        </div>
        <div class="card-footer text-muted">
          Tipo de archivos aceptados: | pdf |
        </div>
      </div>
    </form>

    <div class="container result mat-elevation-z8" *ngFor="let resp of docusfirmados" style="margin-top: 25px">
      <div class="animated fadeIn slow">
        <div class="row col-12">
          <div class="row col-10">
            <div class="left-card col-12"  style="align-items: start">
              <p class="card-text"><b>CERTIFICADO:</b> {{ resp.numeroCertificado }}</p>
              <p class="card-text"><b>FOLIO:</b> {{ resp.folio }}</p>
              <p class="card-text"><b>FECHA:</b> {{ resp.fecha }}</p>
              <p class="card-text"><b>CADENA ORIGINAL:</b> {{ resp.cadenaOriginal }}</p>
              <p class="card-text" style="overflow-wrap: anywhere"><b>SELLO DIGITAL:</b> {{ resp.selloDigital }}</p>
              <a class="text-muted">LINK DE COMPROBACIÓN: <a href="{{ resp.url }}" target="_blank">{{ resp.url }} </a></a>
            </div>
          </div>
          <div class="left-card col-2">
            <img src="../../../assets/images/pdf.svg" width="29px" height="44px">
            {{ resp.documento }}
            <button type="button" mat-raised-button color="primary" (click)="modifyPdf(resp, false)">
              Descargar pdf
            </button>
            <ngx-qrcode
              id="qr"
              [scale]="3"
              [elementType]="elementType"
              [errorCorrectionLevel]="correctionLevel"
              [value]="resp.url">
            </ngx-qrcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
