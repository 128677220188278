import { Component, OnInit } from '@angular/core';
import { DocuFirmado } from 'src/app/modelos/docuFirmado.model';
import { ApiService } from '../../services/api.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, NgForm, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PDFDocument, PDFFont, PDFPage, rgb, StandardFonts } from "pdf-lib";
import * as htmlToImage from "html-to-image";
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from "@techiediaries/ngx-qrcode";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Option {
  id: number,
  name: string
}

@Component({
  selector: 'app-firmarmultiple',
  templateUrl: './firmarmultiple.component.html',
  styleUrls: ['./firmarmultiple.component.css']
})


export class FirmarmultipleComponent implements OnInit {

  pages: Option[] = [
    { id: 1, name: '1 hoja' },
    { id: 2, name: '2 hojas o más' }
  ];
  signs: Option[] = [
    { id: 1, name: 'Primera página' },
    { id: 2, name: 'Última página' },
    { id: 3, name: 'Hoja extra' },
    { id: 4, name: 'Especificar' }
  ];

  signsFiltered: Option[] = [
    { id: 1, name: 'Primera página' },
    { id: 3, name: 'Hoja extra' },
  ];

  formOptions: UntypedFormGroup;

  docusfirmados: DocuFirmado[] = [];
  filesToUpload: Array<File> = [];
  filesFailed: File[] = [];

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  margin = 38;
  lineHeight = 10;
  textSize = 7.5;

  cerFile: File = null;
  keyFile: File = null;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private fb: FormBuilder,
    private _snackbar: MatSnackBar) {
    this.formOptions = this.fb.group({
      pages: new UntypedFormControl(this.pages[0], [Validators.required]),
      sign: new UntypedFormControl(this.signsFiltered[0], [Validators.required]),
      number: new UntypedFormControl(null)
    });
  }

  ngOnInit() {
  }


  upload(form: NgForm) {
    console.log("Click")
    const formData: FormData = new FormData();
    this._snackbar.open('Iniciando carga... por favor manténgase en la pantalla y siga el proceso en la parte inferior', "", {
      duration: 3000,
      verticalPosition: "top",
      panelClass: "green-snackbar",
    });
    for (let i = 0; i < this.filesToUpload.length; i++) {
      const file = this.filesToUpload[i];
      this.validateFormsValues(file).then((res) => {
        if (res) {
          formData.append('tipoRespuesta', 'json');
          formData.append('idAplicacion', '19');
          formData.append('contrasenaAplicacion', 'FirElecApp');
          formData.append('referencia', '');
          formData.append('verificarUrl', 'true');
          formData.append('modo', 'firmarDocumentoPortal');
          formData.append('archivoCer', this.cerFile);
          formData.append('archivoKey', this.keyFile);
          formData.append('contrasenaKey', form.value.contrasenaKey);
          formData.append('archivoPdf', file);
          this.apiService.saveDocument(formData).subscribe((response: any) => {
            if (response.success) {
              const name = form.value.prefix + file.name;
              this.handleDocument(file, response, true, name);
              this.docusfirmados.push(response);
            } else {
              this.filesFailed.push(response)
              this._snackbar.open(response.mensaje, "", {
                duration: 3000,
                panelClass: "green-snackbar",
              });
            }
          },
            (error) => {
              console.log(error)
              this.filesFailed.push(file);
            }
          );
        } else {
          console.log("FileFailed->", file);
          this.filesFailed.push(file);
        }
      })
    }
  }


  generarPdf(response: DocuFirmado) {
    let name = response.documento;
    let file = this.filesToUpload.find(x => x.name === name);
    this.handleDocument(file, response, false, name);
  }

  handleDocument(file: File, resp: DocuFirmado, option: boolean, name: string): any {
    let position = this.formOptions.controls['sign'].value;

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async () => {
      const service = this.apiService;
      const pdfDoc = await PDFDocument.load(reader.result);
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      let pages = pdfDoc.getPages();
      let pageToSign = this.handlePageToSign(pages.length);
      if (pageToSign === pages.length) {
        pdfDoc.addPage([595, 842]);
        pages = pdfDoc.getPages();
      }
      const secondPage = pages[pageToSign];
      const { width, height } = secondPage.getSize();
      let startY = (position.id !== 3 && position.id !== 2) ? 170 : height - 180;
      let x = 25;
      /*
      * Escritura de la primera parte del contenido que no es posible que contenga overflow
      * */
      this.writeToPage(secondPage, "Certificado: \n" + resp.numeroCertificado, startY, helveticaFont);
      this.writeToPage(secondPage, "Folio: \n" + resp.folio, startY - x, helveticaFont);
      this.writeToPage(secondPage, "Fecha: \n" + resp.fecha, startY - (x * 2), helveticaFont)
      this.writeToPage(secondPage, "Cadena original: \n" + resp.cadenaOriginal, startY - (x * 3), helveticaFont)

      /*
      * Escritura del contenido que tiene overflow y debe ser multilínea
      * */
      this.writeToPage(secondPage, "Sello digital:", startY - (x * 4), helveticaFont);
      let text = resp.selloDigital;
      let start = 0;
      let spliced: string[] = []
      //Multiline del string que contiene el sello digital
      for (start; start < text.length;) {
        spliced.push(text.slice(start, start + 120))
        start = start + 120;
      }
      let y = startY - ((x * 4) + 10);
      for (let i = 0; i < spliced.length; i++) {
        this.writeToPage(secondPage, spliced[i], y - (i * 10), helveticaFont);
      }
      /*
      * Dibujo de la imagen generada por el QR
      */
      htmlToImage.toPng(document.getElementById(resp.folio)).then(async function (dataUrl) {
        const jpgImage = await pdfDoc.embedPng(dataUrl);
        const jpgDims = jpgImage.scale(0.2)
        secondPage.drawImage(jpgImage, {
          x: width - 150,
          y: startY - (x * 2),
          width: jpgDims.width,
          height: jpgDims.height,
        });
        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        if (option) {
          service.loadFile(blob, resp.folio, name).subscribe(() => {

          }, error => {
          });
        } else {
          //const url2 = window.URL.createObjectURL(blob);
          //window.open(url2);
          FileSaver.saveAs(blob, name)
        }
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    }
  }

  onFileSelected(files: any, type: string) {
    switch (type) {
      case 'cer':
        this.cerFile = files.target.files[0];
        break;
      case 'key':
        this.keyFile = files.target.files[0];
        break;
      case 'pdf':
        const fileConst = files.target.files;
        for (let i = 0; i < fileConst.length; i++) {
          this.filesToUpload.push(fileConst[i])
        }
        break;
      default:
        return;
    }
  }

  handleChangeSign(event: any) {
    if (event.value.id === 2) {
      this.signsFiltered = this.signs;
    } else {
      this.signsFiltered = this.signs.filter(s => s.id === 1 || s.id === 3);
    }
  }

  validNumberOfPages(pages: number, numberPages: number) {
    return (numberPages === 1 && pages === 1) || (numberPages === 2 && pages >= 2);
  }

  writeToPage(page: PDFPage, text: string, positionY: number, font: PDFFont) {
    page.drawText(text, {
      x: this.margin,
      y: positionY,
      lineHeight: this.lineHeight,
      size: this.textSize,
      font: font,
      color: rgb(0, 0, 0),
    })
  }

  private handlePageToSign(length: number): number {
    switch (this.formOptions.controls['sign'].value.id) {
      case 1:
        return 0;
      case 2:
        return length - 1;
      case 3:
        return length;
      case 4:
        return this.formOptions.controls['number'].value - 1;
      default:
        return 0;
    }
  }

  validateFormsValues(file: File): Promise<boolean> {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise<boolean>((resolve, reject) => {
      reader.onload = async () => {
        let numberPages = this.formOptions.controls['pages'].value;
        const pdfDoc = await PDFDocument.load(reader.result);
        let pages = pdfDoc.getPages();
        let pageToSign = this.handlePageToSign(pages.length);
        if (!this.validNumberOfPages(pages.length, numberPages.id) || (pageToSign > pages.length)) {
          resolve(false);
        }
        resolve(true);
      }
    })

  }

  handleClickClean() {
    this.filesToUpload = [];
    this.cerFile = null;
    this.keyFile = null;
  }
  handleClickCleanSigned() {
    this.docusfirmados = [];
    this.filesToUpload = [];
  }

  isDownloading = false;
  downloaded: any[] = [];

  downloadFiles() {
    this.isDownloading = true;
    const zip = new JSZip();
    const name = new Date().toLocaleDateString() + '.zip';
    const arrUrls = this.docusfirmados.map((el) => el.folio.toString())
    this.apiService.getZip(arrUrls).subscribe((result) => {
      FileSaver.saveAs(result, name);
      this.isDownloading = false;
      this.downloaded = [];
    })

  }
}
