import { Component, OnInit } from '@angular/core';
import { DocCancelado } from 'src/app/modelos/docCancelado.model';
import { ApiService } from '../../services/api.service';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-cancelaciones',
  templateUrl: './cancelaciones.component.html',
  styleUrls: ['./cancelaciones.component.css']
})


export class CancelacionesComponent implements OnInit {


 // API_SERVER = 'http://10.1.16.91/FirmaElectronicaServicios/';  // SIN COORS

  // API_SERVER = 'http://10.1.61.8:8080/FirmaElectronicaServicios/';
  API_SERVER = 'https://efirma.veracruz.gob.mx/FirmaElectronicaServicios/';


  docucancelado: DocCancelado[] = [];


  constructor(private http: HttpClient, private apiService: ApiService) { }

  ngOnInit() {
  }


  public verDatos(form: NgForm): void {

    console.group( 'VER MODELO DE MI FORM' );
    console.log( 'Email:', form.value.modo );
    console.log( 'EL PDF:', form.value.archivoPdf );
    console.log( 'ELARCHIVO CER:', form.value.archivoCer );
    console.groupEnd();

    }


  Cancelar(form: NgForm) {

    const formData = new FormData();

    formData.append('idRegistro', '213');
    formData.append('contrasenaRegistro', 'FirElec');
    formData.append('idAplicacion', '19');
    formData.append('contrasenaAplicacion', 'FirElecApp');
    formData.append('folios', form.value.folios);

    // tslint:disable-next-line: max-line-length
    this.http.get<DocCancelado[]>(`${this.API_SERVER}cancelacionDocumento.do?idRegistro=213&contrasenaRegistro=FirElec&idAplicacion=19&contrasenaAplicacion=FirElecApp&folios=${form.value.folios}`)
      .subscribe((response: any) => {
        this.docucancelado.push(response);
        console.log('Respuesta, ', this.docucancelado);
      });

}


}
