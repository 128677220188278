<div class="container" style="margin-top:16px">
 <div class="search">
   <mat-form-field appearance="outline" style="width: 600px">
     <mat-label>Buscar usuario</mat-label>
     <input (keyup)="applyFilter($event)" matInput placeholder="Usuario" #input>
   </mat-form-field>
   <button  mat-raised-button color="primary" (click)="openDialog()">Agregar usuario</button>
 </div>

  <table [dataSource]="dataSource" mat-table>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID. </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Usuario </th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>

    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef> Area </th>
      <td mat-cell *matCellDef="let element"> {{element.area}} </td>
    </ng-container>

    <ng-container matColumnDef="enabled">
      <th mat-header-cell *matHeaderCellDef> Habilitado </th>
      <td mat-cell *matCellDef="let element"> 
        <mat-slide-toggle [checked]="element.enabled" (change)="change($event.checked, element.id)"></mat-slide-toggle>
      </td>
    </ng-container>

     <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Editar </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-icon-button (click)="openDialog(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" aria-label="Select page of periodic elements" showFirstLastButtons>
  </mat-paginator>
</div>