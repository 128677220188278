import {Component, OnInit} from '@angular/core';
import {DocuFirmado} from 'src/app/modelos/docuFirmado.model';
import {ApiService} from '../../services/api.service';
import {HttpClient} from '@angular/common/http';
import {
  FormBuilder, FormControl,
  FormGroup,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from "@techiediaries/ngx-qrcode";
import {PDFDocument, PDFFont, PDFPage, rgb, StandardFonts} from "pdf-lib";
import * as htmlToImage from "html-to-image";
import {MatSnackBar} from "@angular/material/snack-bar";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Option {
  id: number,
  name: string
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  pages: Option[] = [
    {id: 1, name: '1 hoja'},
    {id: 2, name: '2 hojas o más'}
  ];
  signs: Option[] = [
    {id: 1, name: 'Primera página'},
    {id: 2, name: 'Última página'},
    {id: 3, name: 'Hoja extra'},
    {id: 4, name: 'Especificar'}
  ];

  signsFiltered: Option[] = [
    {id: 1, name: 'Primera página'},
    {id: 3, name: 'Hoja extra'},
  ];

  formOptions: UntypedFormGroup;

  docusfirmados: DocuFirmado[] = [];

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.MEDIUM;

  margin = 38;
  lineHeight = 10;
  textSize = 7.5;

  form: FormGroup;

  cerFile: File = null;
  keyFile: File = null;
  pdfFile: File = null;

  result: boolean = true;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private fb: FormBuilder,
    private _snackbar: MatSnackBar) {
    this.formOptions = this.fb.group({
      pages: new UntypedFormControl(this.pages[0], [Validators.required]),
      sign: new UntypedFormControl(this.signsFiltered[0], [Validators.required]),
      number: new UntypedFormControl(null)
    });
    this.form = this.fb.group({
      archivoCer: new FormControl(null, [Validators.required]),
      archivoKey: new FormControl(null, [Validators.required]),
      contrasenaKey: new FormControl('', [Validators.required]),
      archivoPdf: new FormControl(null, [Validators.required]),
    })
  }

  ngOnInit() {
  }

  async Firmar() {
    if (!this.formOptions.valid) {
      this._snackbar.open('Por favor seleccione una opción para las páginas', "", {
        duration: 3000,
        panelClass: "green-snackbar",
      });
      return;
    }
    if (!this.form.valid) {
      this._snackbar.open('Faltan campos por llenar', "", {
        duration: 3000,
        panelClass: "green-snackbar",
      });
      return;
    }
    this.validateFormsValues().then(res => {
      if(res){
        const formData = new FormData();
        formData.append('tipoRespuesta', 'json');
        formData.append('idAplicacion', '19');
        formData.append('contrasenaAplicacion', 'FirElecApp');
        formData.append('referencia', '');
        formData.append('verificarUrl', 'true');
        formData.append('modo', 'firmarDocumentoPortal');
        formData.append('archivoCer', this.cerFile);
        formData.append('archivoKey', this.keyFile);
        formData.append('contrasenaKey', this.form.controls['contrasenaKey'].value);
        formData.append('archivoPdf', this.pdfFile);
        this.apiService.saveDocument(formData).subscribe(
          (response: any) => {
            if (response.success) {
              this.modifyPdf(response, true);
              this.docusfirmados = [];
              this.docusfirmados.push(response);
            }else{
              this._snackbar.open(response.mensaje, "", {
                duration: 8000,
                panelClass: "green-snackbar",
              });
            }
          },
          (error) => console.log(error)
        );
      }
    });
  }


  modifyPdf(resp: DocuFirmado, option: boolean) {
    let position = this.formOptions.controls['sign'].value;
    const reader = new FileReader();
    reader.readAsArrayBuffer(this.pdfFile);
    reader.onload = async () => {
      const service = this.apiService;
      const pdfDoc = await PDFDocument.load(reader.result);
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      let pages = pdfDoc.getPages();
      let pageToSign = this.handlePageToSign(pages.length);
      if (pageToSign === pages.length) {
        pdfDoc.addPage([595, 842]);
        pages = pdfDoc.getPages();
      }
      const secondPage = pages[pageToSign];
      const {width, height} = secondPage.getSize();
      let startY = (position.id !== 3) ? 170 : height - 40;
      let x = 25;
      /*
      * Escritura de la primera parte del contenido que no es posible que contenga overflow
      * */
      this.writeToPage(secondPage, "Certificado: \n" + resp.numeroCertificado, startY, helveticaFont);
      this.writeToPage(secondPage, "Folio: \n" + resp.folio, startY - x, helveticaFont);
      this.writeToPage(secondPage, "Fecha: \n" + resp.fecha, startY - (x * 2), helveticaFont)
      this.writeToPage(secondPage, "Cadena original: \n" + resp.cadenaOriginal, startY - (x * 3), helveticaFont)

      /*
      * Escritura del contenido que tiene overflow y debe ser multilínea
      * */
      this.writeToPage(secondPage, "Sello digital:", startY - (x * 4), helveticaFont);
      let text = resp.selloDigital;
      let start = 0;
      let spliced: string[] = []
      //Multiline del string que contiene el sello digital
      for (start; start < text.length;) {
        spliced.push(text.slice(start, start + 120))
        start = start + 120;
      }
      let y = startY - ((x * 4) + 10);
      for (let i = 0; i < spliced.length; i++) {
        this.writeToPage(secondPage, spliced[i], y - (i * 10), helveticaFont);
      }
      /*
      * Dibujo de la imagen generada por el QR
      */
      htmlToImage.toPng(document.getElementById('qr')).then(async function (dataUrl) {
        const jpgImage = await pdfDoc.embedPng(dataUrl);
        const jpgDims = jpgImage.scale(0.3)
        secondPage.drawImage(jpgImage, {
          x: 510,
          y: startY - (x * 2),
          width: jpgDims.width,
          height: jpgDims.height,
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], {type: 'application/pdf'});

        if (option) {
          service.loadFile(blob, resp.folio, resp.documento).subscribe((res) => {
          }, error => {
          });
        } else {
          const url2 = window.URL.createObjectURL(blob);
          window.open(url2);
        }
      })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
      this.result = true;
    }

  }

  onFileSelected(files: any, type: string) {
    switch (type) {
      case 'cer':
        this.cerFile = files.target.files[0];
        break;
      case 'key':
        this.keyFile = files.target.files[0];
        break;
      case 'pdf':
        this.pdfFile = files.target.files[0];
        break;
      default:
        return;

    }
  }

  validNumberOfPages(pages: number, numberPages: number) {
    return (numberPages === 1 && pages === 1) || (numberPages === 2 && pages >= 2);
  }

  private handlePageToSign(length: number): number {
    switch (this.formOptions.controls['sign'].value.id) {
      case 1:
        return 0;
      case 2:
        return length - 1;
      case 3:
        return length;
      case 4:
        return this.formOptions.controls['number'].value - 1;
      default:
        return 0;
    }
  }

  handleChangeSign(event: any) {
    if (event.value.id === 2) {
      this.signsFiltered = this.signs;
    } else {
      this.signsFiltered = this.signs.filter(s => s.id === 1 || s.id === 3);
    }
  }

  writeToPage(page: PDFPage, text: string, positionY: number, font: PDFFont) {
    page.drawText(text, {
      x: this.margin,
      y: positionY,
      lineHeight: this.lineHeight,
      size: this.textSize,
      font: font,
      color: rgb(0, 0, 0),
    })
  }

  handleCleanForm() {
    this.form.controls['archivoCer'].setValue(null)
    this.form.controls['archivoKey'].setValue(null)
    this.form.controls['contrasenaKey'].setValue('')
    this.form.controls['archivoPdf'].setValue(null)
  }

  validateFormsValues() : Promise<boolean> {
    const reader = new FileReader();
    reader.readAsArrayBuffer(this.pdfFile);
    return new Promise<boolean>((resolve, reject ) => {
      reader.onload = async () => {
        let numberPages = this.formOptions.controls['pages'].value;
        const pdfDoc = await PDFDocument.load(reader.result);
        let pages = pdfDoc.getPages();
        let pageToSign = this.handlePageToSign(pages.length);
        if (!this.validNumberOfPages(pages.length, numberPages.id)) {
          this._snackbar.open('El número de páginas del archivo no corresponde al número seleccionado', "", {
            duration: 5000,
            panelClass: "green-snackbar",
          });
          resolve(false);
        } else if (pageToSign > pages.length) {
          this._snackbar.open('La página ingresada es mayor al número de páginas del archivo', "", {
            duration: 5000,
            panelClass: "green-snackbar",
          });
          resolve(false);
        }
        resolve(true);
      }
    })

  }
}
