import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { DocumentoFirmar } from '../documentoFirmar';
import { Observable } from 'rxjs';
import { DocuFirmado } from "../modelos/docuFirmado.model";
import { environment } from "../../environments/environment";
import { FileSignedResponse } from "../modelos/fileSignedResponse";
import { DocCancelado } from "../modelos";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  saveDocument(form: FormData): Observable<any> {
    return this.httpClient.post(environment.api + 'servicioFirmaPublicoGeneral.do', form);
  }

  cancelDocument(folio: string) {
    return this.httpClient.get<DocCancelado[]>(environment.api + 'cancelacionDocumento.do?idRegistro=213&contrasenaRegistro=FirElec&idAplicacion=19&contrasenaAplicacion=FirElecApp&folios=' + folio);
  }

  loadFile(blob: Blob, folio: string, name: string): Observable<any> {
    let data = new FormData();
    data.append("file", blob);
    data.append("folio", folio);
    data.append("name", name);
    return this.httpClient.post(environment.url + 'documento', data);
  }

  getAllFiles(): Observable<any> {
    return this.httpClient.get(environment.url + 'documento');
  }

  getUrlByFile(uuid: string): Observable<any> {
    return this.httpClient.get(environment.url + 'documento/' + uuid + '/url', {
      responseType: 'text',
      headers: new HttpHeaders().set('Content-Type', 'text/plain')
    });
  }

  cancelFile(uuid: string): Observable<any> {
    return this.httpClient.delete(environment.url + 'documento/' + uuid);
  }

  getDocumentsByRangeDate<T>(startDate: String, endDate: String): Observable<T> {
    return this.httpClient.get<T>(environment.url + `documento/${startDate}/range/${endDate}`);
  }

  getFiles(data: String[]): Observable<string[]> {
    return this.httpClient.post<string[]>(environment.url + `documento/urls`, data);
  }

  getZip(data: String[]): Observable<Blob> {
    return this.httpClient.post<Blob>(environment.url + `documento/objetos`, data, {responseType : 'blob' as 'json'});
  }

}
