import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialogClose,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RegisterUser } from "src/app/modelos";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-register-user",
  templateUrl: "./register-user.component.html",
  styleUrls: ["./register-user.component.css"],
})
export class RegisterUserComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: RegisterUser,
    public dialogRef: MatDialogRef<RegisterUserComponent>,
    public _snackbar: MatSnackBar
  ) {
    this.initForm();
    if (data) {
      this.form.controls["password"].clearValidators();
      this.form.patchValue(data);
    }
  }

  ngOnInit(): void {}

  get formValue() {
    return this.form.value;
  }

  saveUser() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.data && this.data.id) {
      this.userService
        .putUser(this.data.id, this.formValue)
        .subscribe((response) => {
          if (response) {
            this._snackbar.open(
              "Se ha modificado la información del usuario",
              "Cerrar",
              {
                duration: 2000,
              }
            );
            this.dialogRef.close({ response: true });
          }
        });
      return;
    }

    this.userService.postUser(this.formValue).subscribe((response) => {
      if (response) {
        this._snackbar.open("Se ha creado un nuevo usuario", "Cerrar", {
          duration: 2000,
        });
        this.dialogRef.close({ response: true });
      }
    });
  }

  isFieldInvalid(field: string) {
    return !!this.form.get(field)?.errors?.["required"];
  }

  initForm() {
    this.form = this.fb.group({
      firstName: new FormControl("", [Validators.required]),
      firstLastName: new FormControl("", [Validators.required]),
      secondLastName: new FormControl("", [Validators.required]),
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      email: new FormControl("", [Validators.required]),
      municipality: new FormControl("", [Validators.required]),
      institution: new FormControl("", [Validators.required]),
      area: new FormControl("", [Validators.required]),
      position: new FormControl("", [Validators.required]),
      curp: new FormControl("", [Validators.required]),
      rfc: new FormControl("", [Validators.required]),
      rol: new FormControl(null),
    });
  }
}
