<div class="headerC">
    <h1>CANCELACIONES</h1>
  </div>

<br><br>

<form #f = "ngForm" (ngSubmit) = "f.form.valid && Cancelar(f)">

  <div class="form-row animated fadeIn slow">

      <div class="form-group col-md-4">
        <label for="folio">FOLIO: </label>
        <input name="folios" ngModel  #folios="ngModel"  type="text"  class="form-control" id="folios" [ngClass]="{ 'is-invalid': f.submitted && folios.invalid }" required>
        <div *ngIf="f.submitted && folios.invalid" class="invalid-feedback">
          <div *ngIf="folios.errors.required">Se requiere un número de Folio para cancelar</div>
      </div>
      </div>

  </div>

  <br>

    <div class="form-row">

      <div class="col-md-6">
          <button class="btn btn-secondary" type="reset">Limpiar</button>
      </div>

      <div class="col-md-6">
        <input type="submit" class="btn btn-danger" value="CANCELAR FIRMA">
      </div>

    </div>

</form>

<br>
<hr/>
<br>

<div class="row" *ngFor = "let resp of docucancelado">

    <div class="col-sm-12">
    <div *ngIf="resp.success; else unError" class="alert alert-exito fade in alert-dismissable show" role="alert">

      <strong> <img src="../../../assets/images/pdf.svg" width="29px" height="44px"> FOLIO: {{ resp.folio }} </strong> <br>
      <strong> <img src="../../../assets/images/check.svg" width="20px" height="20px"> {{ resp.mensaje }}  </strong> <br>
      <strong> {{ resp.fecha }}  </strong> <br>
    </div>

    </div>


<!-- <div *ngIf="resp.success; else unError" class="card text-center animated fadeIn slow">
  <div class="card-header">
    <img src="../../../assets/images/pdf.svg" width="29px" height="44px"> {{ resp.folio }}
  </div>
  <div class="card-body">
      <p class="card-text">{{ resp.mensaje }}</p>
    <p class="card-text"><b>FECHA DE CANCELACIÓN:</b>  {{ resp.fecha }}</p>
    <p class="card-text"></p>
  </div>
  <a class="card-footer text-muted">
    .....
  </a>
  </div> -->

  <!-- <div class="alert alert-custom  fade in alert-dismissable show" style="margin-top:18px;"> -->


  <ng-template #unError>
      <div class="alert alert-custom fade in alert-dismissable show" role="alert">
          <strong> {{ resp.mensaje }}  </strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Cerrar">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
  </ng-template>

</div>
