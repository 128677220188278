<mat-toolbar>
  <mat-toolbar-row class="toolbar-row">
    <button mat-icon-button color="primary" (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <section class="nav-items">
      <div class="logos-veracruz"></div>
      <menu class="info-navbar">
        <!--<mat-slide-toggle class="mr-8" [formControl]="toggleControl">
        </mat-slide-toggle>
        <mat-label>{{ name | async }}</mat-label>-->
        <!--<mat-icon (click)="onDashboard()">home</mat-icon>-->
        {{ user | uppercase }}
        <mat-icon (click)="onLogout()">logout</mat-icon>
      </menu>
    </section>
  </mat-toolbar-row>
  <mat-toolbar-row class="toolbar-row">
    <div class="bar">
      Sistema para la firma electrónica de documentos
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container hasBackdrop="true" (backdropClick)="sidenav.close()">
  <mat-sidenav #sidenav mode="side">
    <mat-nav-list>
      <a class="nav-link" routerLink="/dashboard" (click)="sidenav.close()">
       <div class="list-menu">
         <mat-icon>insert_drive_file</mat-icon>
         <strong class="menu-title">Firmar Individual</strong>
       </div>
      </a>
      <a class="nav-link" routerLink="/firmamultiple" (click)="sidenav.close()">
        <div class="list-menu">
          <mat-icon>file_copy</mat-icon>
          <strong class="menu-title">Firmar Múltiple</strong>
        </div>
      </a>
      <!--<a class="nav-link" routerLink="/misdocumentos" (click)="sidenav.close()">
        <div class="list-menu">
          <mat-icon>list</mat-icon>
          <strong class="menu-title">Mis Firmados</strong>
        </div>
      </a>-->
      <a *ngIf="isAdmin()" class="nav-link" (click)="sidenav.close()" routerLink="/usuarios">
        <div class="list-menu">
          <mat-icon>person</mat-icon>
          <strong class="menu-title"> Usuarios </strong>
        </div>
      </a>
<!--      <a class="nav-link" routerLink="/cancelaciones" (click)="sidenav.close()">-->
<!--        <div class="list-menu">-->
<!--          <mat-icon>list</mat-icon>-->
<!--          <strong class="menu-title">Cancelaciones</strong>-->
<!--        </div>-->
<!--      </a>-->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="background-color: rgba(238, 237, 237, 0.73);">
    <div class="my-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

