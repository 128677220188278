import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { RegisterUser } from "src/app/modelos";
import { UserService } from "src/app/services/user.service";
import { RegisterUserComponent } from "../register-user/register-user.component";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  subscription: Subscription;

  dataSource: MatTableDataSource<RegisterUser>;
  displayedColumns = ["id", "name", "username", "area", "enabled", "edit"];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _userService: UserService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getUsers();
    this.subscription = this._userService.refresh$.subscribe(() => {
      this.getUsers();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async getUsers() {
    const response = await this._userService.getUser().toPromise();
    this.dataSource = new MatTableDataSource(response);
    this.dataSource.paginator = this.paginator;
  }

  openDialog(row: RegisterUser = null) {
    this.dialog.open(RegisterUserComponent, {
      panelClass: "my-dialog-container",
      width: "900px",
      height: "auto",
      data: row,
    });
  }

  disabledUser(id: number) {
    this._userService.deleteUser(id).subscribe();
  }

  change(e: boolean, id: number) {
    this.disabledUser(id);
  }
}
