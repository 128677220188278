<div mat-dialog-title>
  Registrar usuario
</div>
<div class="container" mat-dialog-content>
  <form [formGroup]="form" autocomplete="off">
    <div class="row">
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput type="text" formControlName="firstName"/> 
        <mat-error *ngIf="isFieldInvalid('firstName')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Primer apellido</mat-label>
        <input matInput type="text" formControlName="firstLastName"> 
        <mat-error *ngIf="isFieldInvalid('firstLastName')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Segundo apellido</mat-label>
        <input matInput type="text" formControlName="secondLastName"> 
        <mat-error *ngIf="isFieldInvalid('secondLastName')">
          Campo requerido
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Nombre usuario</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="username">
        <mat-error *ngIf="isFieldInvalid('userName')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline" *ngIf="!data">
        <mat-label>Contraseña</mat-label>
        <input matInput type="password" 
          autocomplete="new-password" 
          formControlName="password"
        >
        <mat-error *ngIf="isFieldInvalid('password')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Correo</mat-label>
        <input matInput type="email" formControlName="email">
        <mat-error *ngIf="isFieldInvalid('email')">
          Campo requerido
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Municipio</mat-label>
        <input matInput type="text" formControlName="municipality">
        <mat-error *ngIf="isFieldInvalid('municipality')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Institución</mat-label>
        <input matInput type="text" formControlName="institution">
        <mat-error *ngIf="isFieldInvalid('institution')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Area</mat-label>
        <input matInput type="text" formControlName="area">
        <mat-error *ngIf="isFieldInvalid('area')">
          Campo requerido
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row" >
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Posición</mat-label>
        <input type="text" matInput formControlName="position">
        <mat-error *ngIf="isFieldInvalid('position')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>Curp</mat-label>
        <input matInput type="text" formControlName="curp">
        <mat-error *ngIf="isFieldInvalid('curp')">
          Campo requerido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>RFC</mat-label>
        <input matInput type="text" formControlName="rfc">
        <mat-error *ngIf="isFieldInvalid('rfc')">
          Campo requerido
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="actions">
  <button mat-dialog-close class="float-right" type="submit" mat-raised-button >Cancelar</button>
  <button class="float-right" (click)="saveUser()" type="submit" mat-raised-button color="primary">Guardar</button>
</div>