import { RouterModule, Routes } from "@angular/router";

import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { PagesComponent } from "./pages/pages.component";
import { MisdocumentosComponent } from "./pages/misdocumentos/misdocumentos.component";
import { PagenotfoundComponent } from "./shared/pagenotfound/pagenotfound.component";
import { FirmarmultipleComponent } from "./pages/firmarmultiple/firmarmultiple.component";
import { CancelacionesComponent } from "./pages/cancelaciones/cancelaciones.component";
import { LoginComponent } from "./pages/login/login.component";
import { AuthGuard } from "./guard/auth.guard";
import { UsersComponent } from "./pages/users/users.component";

const appRoutes: Routes = [
  {
    path: "",
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "firmamultiple", component: FirmarmultipleComponent },
      { path: "cancelaciones", component: CancelacionesComponent },
      /*{ path: "misdocumentos", component: MisdocumentosComponent },*/
      { path: "usuarios", component: UsersComponent },
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "**", component: PagenotfoundComponent },
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: true });
