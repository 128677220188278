import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { FileSignedResponse } from "../../modelos/fileSignedResponse";
import { ApiService } from "../../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationModalComponent } from "../../components/confirmation-modal/confirmation-modal.component";
import { SelectionModel } from "@angular/cdk/collections";
import * as JSZip from "jszip";
import * as FileSaver from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-misdocumentos',
  templateUrl: './misdocumentos.component.html',
  styleUrls: ['misdocumentos.component.css']
})
export class MisdocumentosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<FileSignedResponse>();
  columns = ['select', 'folio', 'fecha', 'nombre', 'visualizar', 'cancelar']

  dates = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  selection: SelectionModel<any>;
  downloaded: any[] = [];

  percent: number = 0.0;

  isDownloading: boolean = false;
  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private _snackbar: MatSnackBar) {
    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
  }

  ngOnInit() {
    this.apiService.getAllFiles().subscribe((res) => {
      this.dataSource = new MatTableDataSource<any>(res);
      this.dataSource.paginator = this.paginator;
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  isAnySellected() {
    return this.selection.selected.length !== 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    this.isAllSelected() || this.isAnySellected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  previewFile(element: FileSignedResponse) {
    this.apiService.getUrlByFile(element.uuid).subscribe(async (res) => {
      const url = res.split("¿.?")
      const element = url[1];
      const data = await fetch(element)
      const blob = await data.blob()
      const toopen = window.URL.createObjectURL(blob);
      window.open(toopen, '_blank');
      /*const dialog = this.dialog.open(PdfviewerComponent, {
        panelClass: 'my-dialog-container',
        width: '900px',
        height: '90%',
        data: {
          titulo: element.folio,
          url: url[1]
        }
      })
      dialog.disableClose = false;*/
    })
  }

  getDate(createdDate: string): any {
    return new Date(createdDate).toLocaleDateString();
  }

  cancelDocument(object: FileSignedResponse) {
    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      panelClass: 'my-dialog-container',
      width: '600px',
      data: {
        title: 'Confirmación',
        message: 'Cancelar la firma es una acción que no se puede deshacer, y cancelará la firma del documento acutal, pero permitirá ' +
          'seguir visualizando el documento firmado. ¿Está seguro de cancelar la firma?',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.response) {
        this.apiService.cancelFile(object.uuid).subscribe(res => {
          object.cancelled = true;
          this._snackbar.open('Folio ' + object.folio + ' cancelado', "", {
            duration: 3000,
            panelClass: "green-snackbar",
          });
        })
        this.apiService.cancelDocument(object.folio).subscribe(res => { });
      }
    })

  }

  downloadFiles() {
    if (!this.isAnySellected()) {
      this._snackbar.open('Seleccione un elemento para descargar por lo menos', "", {
        duration: 3000,
        panelClass: "green-snackbar",
      });
      return
    }
    this.isDownloading = true;
    const selected = this.selection.selected;
    const zip = new JSZip();
    const name = new Date().toLocaleDateString() + '.zip';
    selected.forEach(el => {
      this.apiService.getUrlByFile(el.uuid).subscribe(async (res) => {
        const url = res.split("¿.?")
        const element = url[1];
        const data = await fetch(element)
        const blob = await data.blob()
        zip.file(el.name, blob);
        this.downloaded.push(el.name)
      });
    });
    setTimeout(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        if (content) {
          FileSaver.saveAs(content, name);
          this.isDownloading = false;
          this.downloaded = [];
        }
      });
    }, selected.length * 500);
  }

  calculatePercent() {
    const per = ((100 / this.selection.selected.length) * this.downloaded.length);
    return per !== 100 ? Math.round(per) + '% descargado' : 'Procesando descarga, espere por favor hasta que aparezca el archivo para descargar';
  }

  buscarDocumentos() {

    if (!this.dates.controls["start"].value || !this.dates.controls["end"].value) {
      this._snackbar.open('Seleccion un rango de fechas.', "", {
        duration: 3000,
        panelClass: "red-snackbar",
      });
      return
    }

    const startDate = this.formatDate(this.dates.controls["start"].value)
    const endDate = this.formatDate(this.dates.controls["end"].value)

    this.apiService.getDocumentsByRangeDate<FileSignedResponse[]>(startDate, endDate)
      .subscribe(
        result => this.dataSource = new MatTableDataSource<FileSignedResponse>(result)
      )

  }

  formatDate(date: Date) {
    const month: string = (date.getMonth() + 1) > 9
      ? (date.getMonth() + 1).toString()
      : "0" + (date.getMonth() + 1)

    const day: string = date.getDate() > 9
      ? date.getDate().toString()
      : "0" + date.getDate()

    return date.getFullYear() + "-" + month + "-" + day
  }
}
