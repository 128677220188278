import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  private formSubmitAttempt: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  isFieldInvalid(field: string) {
    return !!this.form.get(field).errors["required"];
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.formSubmitAttempt = true;
    }

    this.authService.login(this.form.value).subscribe((response) => {
      if (response && response.access_token) {
        let username = sessionStorage.getItem("usuario");
        this._snackbar.open(`Bienvenido ${username}`, "", {
          duration: 3000,
          panelClass: "green-snackbar",
        });
        this.router.navigate([""]);
      }
    });
  }

  // recuperarPassword() {
  //   this.dialog.open(RecuperarPasswordComponent, {
  //     panelClass: 'my-dialog-container',
  //     width: '400px'
  //   })
  // }
}
