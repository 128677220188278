import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.css']
})
export class PdfviewerComponent implements OnInit {
  url: string
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.url = this.data.url
  }

  ngOnInit() {
  }

}
