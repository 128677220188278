import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { RegisterUser } from "../modelos";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _refresh$ = new Subject<void>();

  constructor(private _http: HttpClient) {}

  get refresh$() {
    return this._refresh$;
  }

  postUser(user: RegisterUser) {
    return this._http.post(environment.url + "usuario", user).pipe(
      tap(() => {
        this._refresh$.next();
      })
    );
  }

  getUser(): Observable<RegisterUser[]> {
    return this._http.get<RegisterUser[]>(environment.url + "usuario");
  }

  deleteUser(id: number) {
    return this._http.delete(environment.url + `usuario/${id}`);
  }

  putUser(id: string, user: RegisterUser) {
    delete user.password;
    user.id = id;
    return this._http.put(environment.url + `usuario/${id}`, user).pipe(
      tap(() => {
        this._refresh$.next();
      })
    );
  }
}
