<div class="headerC">
  <h1>Mis documentos firmados</h1>
</div>
<div class="container">
  <div class="container-data">
    <div class="row">
      <mat-form-field class="col-4" appearance="outline">
        <mat-label>Filtro de búsqueda</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filtro de búsqueda">
      </mat-form-field>
      <div>
        <mat-form-field [formGroup]="dates" appearance="outline">
          <mat-label>Buscar por fecha</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Fecha inicio">
            <input matEndDate formControlName="end" placeholder="Fecha final">
          </mat-date-range-input>
          <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-date-range-picker #picker=""></mat-date-range-picker>
        </mat-form-field>
        <button (click)="picker.open()" mat-icon-button>
          <mat-icon>calendar_today</mat-icon>
        </button>
        <button (click)="buscarDocumentos()" mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div class="col-4 d-flex flex-row align-items-center justify-content-end ">
        <button
          class="col-4"
          type="button"
          mat-raised-button
          color="primary"
          (click)="downloadFiles()">
          Descargar
        </button>
      </div>
    </div>
    <div class="col-12 d-flex flex-row align-items-center justify-content-end">
      <span>
        {{isDownloading ? calculatePercent() : ''}}
      </span>
    </div>
    <div>
      <span>
        {{isAnySellected() ? selection.selected.length : 0}} elementos seleccionados
      </span>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="folio">
          <th mat-header-cell *matHeaderCellDef> Folio </th>
          <td mat-cell *matCellDef="let element"> {{element.folio}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha">
          <th mat-header-cell *matHeaderCellDef> Fecha </th>
          <td mat-cell *matCellDef="let element"> {{getDate(element.createdDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="visualizar">
          <th mat-header-cell class="text-center" *matHeaderCellDef> Visualizar </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex justify-content-center">
              <mat-icon color="primary" (click)="previewFile(element)">picture_as_pdf</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="cancelar">
          <th mat-header-cell class="text-center" *matHeaderCellDef> Cancelar </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex justify-content-center">
              <mat-icon color="primary" (click)="cancelDocument(element)">cancel</mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[20, 100, 500]"
                     showFirstLastButtons
                     aria-label="Selecciona el número de elementos por página">
      </mat-paginator>
    </div>
  </div>

</div>
