import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import jwt_decode from "jwt-decode";
import { User, RegisterUser } from "../modelos";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.hasToken()
  );

  private name: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.getName()
  );

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get nameUser() {
    return this.name.asObservable();
  }

  private hasToken(): boolean {
    return !!sessionStorage.getItem("token");
  }

  private getName(): any {
    return sessionStorage.getItem("usuario");
  }

  _usuario = {};
  token = {};

  constructor(private router: Router, private _http: HttpClient) {}

  login(user: User) {
    return this._http.post(environment.url + "login", user).pipe(
      tap((response: any) => {
        const result: any = jwt_decode(response.access_token);
        sessionStorage.setItem("usuario", result.given_name);
        sessionStorage.setItem("token", response.access_token);
        sessionStorage.setItem("refresh_token", response.refresh_token);
        sessionStorage.setItem("role", result.resource_access.usuarios.roles[0]);

        this._usuario = {
          username: result.given_name,
          token: response.access_token,
          permissions: result.resource_access.usuarios.roles,
        };

        this.loggedIn.next(true);
        this.name.next(result.given_name);

        sessionStorage.setItem("info-user", JSON.stringify(this._usuario));
      }),
      catchError(async (err) => false)
    );
  }

  logout() {
    sessionStorage.clear();
    this.loggedIn.next(false);
    this.router.navigate(["/login"]);
  }

  validarToken(): Observable<boolean> {
    this.token = {
      refreshToken: sessionStorage.getItem("refresh_token"),
    };

    this.loggedIn.next(true);

    return this._http.post<any>(`auth/refresh_token`, this.token).pipe(
      map((resp) => {
        const result: any = jwt_decode(resp.access_token);
        sessionStorage.setItem("token", resp.access_token);
        sessionStorage.setItem("usuario", result.given_name);

        this._usuario = {
          username: result.given_name,
          token: resp.access_token,
          permissions: result.resource_access.usuarios.roles,
        };
        return true;
      }),
      catchError(async (err) => false)
    );
  }

  register(user: RegisterUser) {
    return this._http.post("register", user).pipe(
      tap((response: any) => {
        const result: any = jwt_decode(response.access_token);
        sessionStorage.setItem("usuario", result.given_name);
        sessionStorage.setItem("token", response.access_token);
        sessionStorage.setItem("refresh_token", response.refresh_token);

        this._usuario = {
          username: result.given_name,
          token: response.access_token,
          permissions: result.resource_access.usuarios.roles,
        };

        this.loggedIn.next(true);

        sessionStorage.setItem("info-user", JSON.stringify(this._usuario));
      }),
      catchError(async (err) => false)
    );
  }
}
