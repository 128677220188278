import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["header.component.css"],
})
export class HeaderComponent implements OnInit {

  user: string = ''

  constructor(private _authService: AuthService) {
    this._authService.nameUser.subscribe(result => this.user = result)
  }

  ngOnInit() { }
  onLogout() {
    this._authService.logout();
  }

  onDashboard() {
    //this.router.navigate(['/'])
  }

  isAdmin() {
    return sessionStorage.getItem('role') === 'administrador';
  }
}
