<div class="container-login">
  <section class="logo"></section>
  <section class="container-form">
    <section class="logos-veracruz"></section>
    <h3 class="fs-4"><strong>Iniciar sesión</strong></h3>
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Usuario</mat-label>
        <input matInput placeholder="Usuario" formControlName="username" required>
        <!--<mat-error *ngIf="isFieldInvalid('userName')">
          Porfavor, ingrese el usuario.
        </mat-error>-->
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input type="password" matInput placeholder="Contraseña" formControlName="password" required>
        <!--<mat-error *ngIf="isFieldInvalid('password')">
          Porfavor, ingrese la contraseña.
        </mat-error>-->
        <!-- <mat-hint align="end"><a class="register-link" >Recuperar contraseña</a></mat-hint>-->
      </mat-form-field> 
    </form>
    <button class="w-48 h-12 button-primary" (click)="onSubmit()" type="submit" mat-raised-button color="primary">Ingresar</button>
  </section>
</div>
