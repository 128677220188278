import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// import { DownloadjsModule } from '../../node_modules/downloadjs';

// Rutas
import { APP_ROUTES } from "./app.routes";

// Componentes
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { HeaderComponent } from "./shared/header/header.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { BreadcrumbsComponent } from "./shared/breadcrumbs/breadcrumbs.component";
import { PagenotfoundComponent } from "./shared/pagenotfound/pagenotfound.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { MisdocumentosComponent } from "./pages/misdocumentos/misdocumentos.component";
import { PagesComponent } from "./pages/pages.component";
import { FileInputValueAccessor } from "./file-input.accessor";
import { FirmarmultipleComponent } from "./pages/firmarmultiple/firmarmultiple.component";
import { CancelacionesComponent } from "./pages/cancelaciones/cancelaciones.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./shared/material/material.module";
import { LoginComponent } from "./pages/login/login.component";
import { PdfviewerComponent } from "./components/pdfviewer/pdfviewer.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RegisterUserComponent } from "./pages/register-user/register-user.component";
import { UsersComponent } from "./pages/users/users.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { AuthInterceptor } from "./interceptors/auth.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    PagenotfoundComponent,
    FooterComponent,
    MisdocumentosComponent,
    PagesComponent,
    FileInputValueAccessor,
    FirmarmultipleComponent,
    CancelacionesComponent,
    PdfviewerComponent,
    RegisterUserComponent,
    UsersComponent,
    ConfirmationModalComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    FormsModule,
    HttpClientModule,
    NgxQRCodeModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxExtendedPdfViewerModule,
    MatTooltipModule,
    // DownloadjsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
